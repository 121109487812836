<template>
	<div class="homepage">
		<audio ref="getVoice">
			<source src="../assets/voice/voice.mp3" type="audio/mpeg">
		</audio>
		<div class="nav">
			<div class="cen-nav dp-flex-row">
				<img class="img-logo" :src="pcLogoUrl" alt="">
				<div class="nav-content">
					<ul class="dp-flex-row">
						<li v-for="(item,index) in navList" :key="index">
							<a class="navitem" href="#" v-if="index==0">
								{{item.navName}}
								<img :src="item.imgSrc" alt="" v-if="item.imgSrc">
								<div v-if="navIndex==index" class="navline"></div>
							</a>
							<a class="navitem" :href="item.url" target="_blank" v-if="index>0">
								{{item.navName}}
								
								<img :src="item.imgSrc" alt="" v-if="item.imgSrc">
								<div v-if="navIndex==index" class="navline"></div>
							</a>
						</li>
					</ul>
				</div>
				<div class="langBox dp-flex-row flex-ai-center">
					<img :src="require('../assets/images/'+selLanguage.icon)" alt="">
					<el-dropdown trigger="click" class="selLang" @command="changeLaguages">
						<span class="el-dropdown-link">
							{{selLanguage.label}}<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item v-for="item in langOptions" :key="item.value" :command="item">
								{{item.label}}</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
		</div>
		<div class="top-img">
			<img v-if="templayteName!=''" :src="require('../assets/images/'+templayteName+'/banner_'+nowLang+'.png')"
				alt="">
			<div class="img-prize" :class="'img-prize_'+nowLang">
				<p class="dp-flex-row flex-ai-center prize-title">
					<span>{{ $t("lang.Game") }}</span>
					<span>{{ $t("lang.Member") }}</span>
					<span>{{ $t("lang.Bonus") }}</span>
				</p>
				<div id="review_box" class="review_box">
					<ul id="comment1">
						<li v-for="(item,index) in gameData" :key="index" class="dp-flex-row flex-ai-center">
							<span>{{item[2]}}</span>
							<span>{{item[0]}}</span>
							<span>{{item[3]}}{{item[1]}}</span>
						</li>
					</ul>
					<ul id="comment2"></ul>
				</div>
			</div>
			<div class="img-prize2" :class="'img-prize2_'+nowLang">
				<p class="dp-flex-row flex-ai-center prize-title">
					<span>{{ $t("lang.Member") }}</span>
					<span>{{ $t("lang.Name") }}</span>
					<span>{{ $t("lang.Bonus") }}</span>
				</p>
				<div id="review_box2" class="review_box">
					<ul id="ccomment1">
						<li v-for="(item,index) in menberData" :key="index" class="dp-flex-row flex-ai-center">
							<span>{{item[0]}}</span>
							<span>{{item[2]}}</span>
							<span>{{item[3]}}{{item[1]}}</span>
						</li>
					</ul>
					<ul id="ccomment2"></ul>
				</div>
			</div>
		</div>
		<div class="content-wrap">
			<div class="content dp-flex-column flex-ai-center">
				<div class="search-box dp-flex-row flex-ai-center flex-js-between" >
					<span class="t-vipquery">{{ $t("lang.viplevel_query") }}</span>
					<input type="text" :placeholder="$t('lang.fill_member_account')" v-model="queryVip">
					<div class="btn-query" @click="queryVipLevel">
						{{ $t("lang.query") }}
					</div>
				</div>
				<div class="tab-box dp-flex-row flex-js-between" >
					<div class="tab-item" :class="{tabactive:tabIndex==index}" v-for="(item,index) in tabList"
						:key="index" @click="changeTab(index)">
						<p>{{item}}</p>
					</div>
				</div>
				<div class="tab-content-bgtop"></div>
				<div class="tab-content" v-if="tabIndex==0">
					<p class="content-1">{{ $t("lang.vip_level", { msg: customBeginDate}) }}</p>
					<p class="content-2 dp-flex-row">
						<span>{{ $t("lang.vip_t_exam") }}：</span>
						<span>
							{{ $t("lang.vip_exam") }}
						</span>
					</p>
					<!-- <p class="p-content">{{ $t("lang.vip_level_tip") }}</p> -->
					<p class="p-content" v-if="templayteName != 'U31'">{{ $t("lang.vip_level_tip") }}</p>
					<p class="p-content" v-if="templayteName == 'U31'">{{ $t("lang.vip_level_tip1") }}</p>
					<p class="p-content">{{ $t("lang.vip_level_tip2") }}</p>
					
					<table class="content-3" cellspacing="0">
						<tr>
							<th v-for="item in vipTh" :key="item">{{item}}</th>
						</tr>
						<tr v-for="(item,index) in usdtAdvantages" :key="index" v-if="nowLang == 'usdt'" >
							<td>{{item.name}}</td>
							<td>{{item.a_drop}}</td>
							<td>{{item.promotion}}</td>
							<td>{{item.festival_bonus}}</td>
							<td>{{item.birthday_bonus}}</td>
							<td>{{item.lucky_bonus}}</td>
							<td>{{item.negative_bonus}}</td>
							
							<td><span v-if="item.service==1" class="status correct"></span><span v-if="item.service==0"
									class="status incorrect"></span></td>
						</tr>
						
						<tr v-for="(item,index) in vipAdvantages" :key="index" v-if="nowLang !== 'usdt'" >
							<td>{{item.name}}</td>
							<td>{{item.a_drop_trans}}</td>
							<td>{{item.promotion}}</td>
							<td>
								<span v-if="parseInt(item.festival_bonus)>0">{{item.festival_bonus}}</span><span v-else class="status incorrect"></span>
							</td>
							<td>
								<span v-if="parseInt(item.birthday_bonus)>0">{{item.birthday_bonus}}</span><span v-else class="status incorrect"></span>
							</td>
							<!-- <td>{{item.month_save_promotion}}</td> -->
							<td>{{item.lucky_bonus}}</td>
							<td>{{item.negative_bonus}}</td>
							<!-- <td><span v-if="item.line==1" class="status correct"></span><span v-if="item.line==0"
									class="status incorrect"></span></td> -->
							<td><span v-if="item.service==1" class="status correct"></span><span v-if="item.service==0"
									class="status incorrect"></span></td>
						</tr>
					</table>
					<p class="tip" style="margin-top: 10px;    text-align: center;"> {{ $t("lang.tipBottom") }}</p>
					
					
				</div>
				<!-- <div class="tab1 tab-content" v-if="tabIndex==1">
          <p class="tab1-p1">{{ $t("lang.tab1_p1") }}</p>
          <p class="tab1-p2">{{ $t("lang.tab1_p2") }}</p>
          <p class="tab1-p3">{{ $t("lang.tab1_p3") }}</p>
          <p class="tab1-p4">{{ $t("lang.tab1_p4") }}</p>
          <div class="dp-flex-row flex-js-between">
            <table class="tab1-table1" cellspacing="0">
              <tr>
                <th v-for="item in tab1Th" :key="item">{{item}}</th>
              </tr>
              <tr v-for="(item,index) in luckData1" :key="index">
                <td>{{item.name}}</td>
                <td>{{item.m_drop_trans}}</td>
                <td>{{item.lucky_bonus}}</td>
              </tr>
            </table>
            <table class="tab1-table2" cellspacing="0">
              <tr>
                <th v-for="item in tab1Th" :key="item">{{item}}</th>
              </tr>
              <tr v-for="(item,index) in luckData2" :key="index">
                <td>{{item.name}}</td>
                <td>{{item.m_drop_trans}}</td>
                <td>{{item.lucky_bonus}}</td>
              </tr>
            </table>
            <table class="tab1-table3" cellspacing="0">
              <tr>
                <th v-for="item in tab1Th" :key="item">{{item}}</th>
              </tr>
              <tr v-for="(item,index) in luckData3" :key="index">
                <td>{{item.name}}</td>
                <td>{{item.m_drop_trans}}</td>
                <td>{{item.lucky_bonus}}</td>
              </tr>
            </table>
          </div>
        </div> -->
				<!-- <div class="tab2 tab-content" v-if="tabIndex==2">
          <p class="tab2-p1">{{ $t("lang.tab2_p1") }}</p>
          <p class="tab3-p2">{{ $t("lang.tab2_p2") }}</p>
          <div class="dp-flex-row flex-js-between">
            <table class="tab2-table1" cellspacing="0">
              <tr>
                <th v-for="item in tab2Th" :key="item">{{item}}</th>
              </tr>
              <tr v-for="(item,index) in monthGiftData1" :key="index">
                <td>{{item.name}}</td>
                <td>{{item.month_save_promotion}}</td>
                <td>{{item.apply_count}}</td>
                <td>{{item.promotion_limit}}</td>
                <td v-if="nowLang=='zh'">{{item.withdraw_limit}}{{ $t("lang.bei") }}</td>
                <td v-if="nowLang=='th'||nowLang=='en'">×{{item.withdraw_limit}}</td>
              </tr>
            </table>
            <table class="tab2-table2" cellspacing="0">
              <tr>
                <th v-for="item in tab2Th" :key="item">{{item}}</th>
              </tr>
              <tr v-for="(item,index) in monthGiftData2" :key="index">
                <td>{{item.name}}</td>
                <td>{{item.month_save_promotion}}</td>
                <td>{{item.apply_count}}</td>
                <td>{{item.promotion_limit}}</td>
                <td v-if="nowLang=='zh'">{{item.withdraw_limit}}{{ $t("lang.bei") }}</td>
                <td v-if="nowLang=='th'||nowLang=='en'">×{{item.withdraw_limit}}</td>
              </tr>
            </table>
          </div>
        </div> -->
				<div class="tab3 tab-content" v-if="tabIndex==1">
					<div v-if="nowLang == 'usdt'">
							<p class="content-2 dp-flex-row">
							<span>วีธีการสมัครโบนัสนำโชค?</span>
							
						</p>
						<p class="p-content dp-flex-row" >
							<span>
								ระบบจะแจกโบนัสนำโชคอัตโนมัติโดยไม่ต้องทำการสมัครอย่างต่อเนื่องหลังเวลา14.00 น. (GMT+8) ในวันที่ 5 ของทุกเดือนตามเวลา เนื่องจากโบนัสมีจำนวนมาก อาจมีความล่าช้าในการทำรายการ โปรดอดทนรอหากไม่ได้รับในเวลา
							</span>
						</p>
						<p class="p-content dp-flex-row" >
							<span>
								สมาชิกต้องอยู่ในระดับVIPและจำนวนเงินฝากที่กำหนดก่อน ถึงจะได้รับโบนัสนำโชคที่ที่กำหนดไว้ค่ะ
							</span>
						</p>
						<p class="p-content dp-flex-row" >
							<span>
								ตัวอย่าง สมาชิก A อยู่ระดับ VIP5 ต้องมียอดฝากภายในเดือน 3000 USDT แต่ สมาชิก A มียอดฝากเพียง 1500  USDTไม่ถึงขั้นต่ำที่กำหนด  ดังนั้นจะได้รับตามระดับยอดฝาก VIP4 ได้รับ30USDT
							</span>
						</p>
						<table class="content-3" cellspacing="0">
							<tr>
								<th v-for="item in usdtTh" :key="item">{{item}}</th>
							</tr>
							<tr v-for="(item,index) in usdtAdvantages" :key="index">
								<td>{{item.name}}</td>
								<td>{{item.m_keep}}</td>
								<td>{{item.lucky_bonus}}</td>
							</tr>
						</table>
						
						<p class="content-2 dp-flex-row" >
							<span>วีธีการสมัครโบนัสอัพเกรด?</span>
						</p>
						<p class="p-content dp-flex-row">
							<span>
								สวัสดีค่ะ ยอดเทิร์นที่นำมาอัพเกรดจะถูกคิด 2 วันหลังจากที่สมาชิกได้ทำการเดิมพัน เนื่องจากยอดเทิร์นของแต่ละเกมส์จะอัพเดตในวันถัดไป ระบบจึงจะทำการอัพเกรดให้ในวันที่3หลังจากยอดเทิร์นอัพเดทแล้ว หากมีคำถามเพิ่มเติมกรุณาติดต่อเจ้าหน้าที่ฝ่ายบริการของเรา ขอบคุณค่ะ
							</span>
						</p>
						
						<p class="content-2 dp-flex-row">
							<span>โบนัสยอดเสียประจำเดือน?</span>
						</p>
						<p class="p-content dp-flex-row">
							<span style="color:#000;font-weight: 700;">
								เพียงสมาชิกภายในเดือนมียอดเสีย100USDTขึ้นไป/เดือนและอยู่ในระดับวีไอพีที่เข้าร่วมจะได้รับโบนัสช่วยเหลือเพิ่มดวงจากทางเราสูงสุด 5% ระบบจะแจกโบนัสอัตโนมัติในวันที่ 15 ของทุกเดือน ไม่จำกัดจำนวนโบนัส
							</span>
						</p>
						
						<p class="content-2 dp-flex-row">
							<span>วีธีการสมัครโบนัสวันเกิด?</span>
						</p>
						<p class="p-content dp-flex-row">
							<span>
								ติดต่อฝ่ายบริการลูกค้าออนไลน์เพื่อยืนยันหลักฐาน หลังจากที่ตรวจสอบเรียบร้อยเจ้าหน้าที่จะส่งโบนัสให้คุณในวันเกิดของคุณ
							</span>
						</p>
						<p class="content-2 dp-flex-row" >
							<span>(เพื่อความสะดวกในการดำเนินรายการ กรุณาติดต่อเจ้าหน้าที่เพื่อกรอกข้อมูลวันเกิดให้ครบถ้วน)</span>
						</p>
						<p class="content-2 dp-flex-row">
							<span>วิธีการสมัครโบนัสเทศกาล?</span>
						</p>
						<p class="p-content dp-flex-row" >
							<span>
								หลักเกณฑ์การแจก：ต้องมียอดฝาก 200 ขึ้นไปก่อนวันเทศกาล15วัน ก็มีสิทธิ์เข้าร่วมการแจกโบนัส
							</span>
						</p>
						<p class="content-2 dp-flex-row">
							<span>เกี่ยวกับการให้บริการตลอด 24 ชั่วโมง</span>
						</p>
						<p class="p-content dp-flex-row" >
							<span>
								หากท่านพบปัญหาเกี่ยวกับ การฝาก-ถอน หรือมีข้อสงสัยสามารถติดต่อเจ้าที่ได้ตลอด เรามีเจ้าหน้าที่ให้บริการตลอด 24 ชั่วโมง
							</span>
						</p>
						<p class="p-content " style="text-align: center;margin-top: 40px;">
							<span>
								ข้อความข้างต้นเป็นข้อกำหนดและเงื่อนไขทั่วไป ของ แพลตฟอร์ม
							</span>
						</p>
						
					</div>

					<div class="tab1" v-if="nowLang !== 'usdt'">
						<p class="tab1-p1">{{ $t("lang.tab1_p1") }}</p>
						<p class="tab1-p2" v-if="templayteName != 'U31'">{{ $t("lang.tab1_p2") }}</p>
						<p class="tab1-p2" v-if="templayteName == 'U31'">{{ $t("lang.tab1_p2_u") }}</p>
						<p class="tab1-p3">{{ $t("lang.tab1_p3") }}</p>
						<p class="tab1-p4">{{ $t("lang.tab1_p4") }}</p>
						<div class="dp-flex-row flex-js-between">
							<div class="tabItem" v-if="luckData1.length > 0">
								<table class="tab1-table1" cellspacing="0">
									<tr>
										<th v-for="item in tab1Th" :key="item">{{item}}</th>
									</tr>
									<tr v-for="(item,index) in luckData1" :key="index">
										<td>{{item.name}}</td>
										<td>{{item.m_drop_trans}}</td>
										<td>{{item.lucky_bonus}}</td>
									</tr>
								</table>
							</div>
							<div class="tabItem" v-if="luckData2.length > 0">
								<table class="tab1-table2" cellspacing="0">
									<tr>
										<th v-for="item in tab1Th" :key="item">{{item}}</th>
									</tr>
									<tr v-for="(item,index) in luckData2" :key="index">
										<td>{{item.name}}</td>
										<td>{{item.m_drop_trans}}</td>
										<td>{{item.lucky_bonus}}</td>
									</tr>
								</table>
							</div>
							<div class="tabItem" v-if="luckData3.length > 0">
								<table class="tab1-table3" cellspacing="0">
									<tr>
										<th v-for="item in tab1Th" :key="item">{{item}}</th>
									</tr>
									<tr v-for="(item,index) in luckData3" :key="index">
										<td>{{item.name}}</td>
										<td>{{item.m_drop_trans}}</td>
										<td>{{item.lucky_bonus}}</td>
									</tr>
								</table>
							</div>
						</div>
						
						<p class="tab3-p1">{{ $t("lang.tab3_p1") }}</p>
						<p class="tab3-p2" v-if="templayteName != 'U31'">{{ $t("lang.tab3_p2") }}</p>
						<p class="tab3-p2" v-if="templayteName == 'U31'">{{ $t("lang.tab3_p15") }}</p>
						<p class="tab3-p1">{{ $t("lang.tab3_p3") }}</p>
						<p class="tab3-p2" v-if="templayteName == 'U31'">{{ $t("lang.tab3_p16") }}</p>
						<p class="tab3-p2" v-if="templayteName != 'U31'">{{ $t("lang.tab3_p4") }}</p>
						<p class="tab3-p1">{{ $t("lang.tab3_p5") }}</p>
						<p class="tab3-p3">{{ $t("lang.tab3_p6") }}</p>
						<p class="tab3-p4">{{ $t("lang.tab3_p7") }}</p>
						<p class="tab3-p1">{{ $t("lang.tab3_p8") }}</p>
						<p class="tab3-p2" v-if="templayteName == 'U31'">{{ $t("lang.tab3_p9_u") }}</p>
						<p class="tab3-p2" v-if="templayteName != 'U31'">{{ $t("lang.tab3_p9") }}</p>
						<p class="tab3-p1">{{ $t("lang.tab3_p10") }}</p>
						<p class="tab3-p2">{{ $t("lang.tab3_p11") }}</p>
						<!-- <p class="tab3-p1">{{ $t("lang.tab3_p12") }}</p>
						<p class="tab3-p2">{{ $t("lang.tab3_p13") }}</p> -->
						<p class=" tab3-center">{{ $t("lang.tab3_p14") }}</p>
					</div>
					<p class="tip" style="margin-top: 10px;    text-align: center;"> {{ $t("lang.tipBottom") }}</p>
				</div>
				<div class="tab-content-bgbot"></div>
			</div>
		</div>
		<div class="footer">
			<p class="dp-flex-row flex-ai-center flex-js-center">Copyright &copy; {{footerData}} Reserved</p>
		</div>
		<div class="cover" v-if="isQueryDia">
		</div>
		<div class="dialog-query" v-if="isQueryDia">
			<div class="dia-top">
				<span class="t-queryresult">{{ $t("lang.query_result") }}</span>
				<span class="status incorrect" @click="isQueryDia=false"></span>
			</div>
			<div class="dia-bot dp-flex-column flex-ai-center no-account" v-if="noAccount">{{ $t("lang.no_account") }}
			</div>
			<div class="dia-bot dp-flex-column flex-ai-center" v-else>
				<div class="info dp-flex-row flex-js-between">
					<p>{{ $t("lang.dear") }}: <span>{{queryResult.user_name}}</span></p>
					<p>{{ $t("lang.now_viplevel") }}: <span
							v-if="queryResult.vip_lv=='Normal'&&selLanguage.value=='zh'">{{ $t("lang.normal") }}</span><span
							v-else>{{queryResult.vip_lv}}</span></p>
					<p v-if="queryResult.standard==4||queryResult.standard==5">{{ $t("lang.current_active_bets") }}:
						<span>{{queryResult.bet_sum}}</span></p>
					<p v-if="queryResult.standard==2||queryResult.standard==5">{{ $t("lang.current_save_sum") }}:
						<span>{{queryResult.save_sum}}</span></p>
				</div>
				<div class="vip-levlel">
					<div class="img-vipprivileges" v-if="selLanguage.value=='th'">
						{{ $t("lang.vipprivileges") }}{{queryResult.vip_lv}}</div>
					<div class="img-vipprivileges" v-if="selLanguage.value=='zh'||selLanguage.value=='en'||selLanguage.value=='id'">
						<span v-if="queryResult.vip_lv=='Normal'&&selLanguage.value=='zh'">{{ $t("lang.normal") }}</span>
						<span v-else>{{queryResult.vip_lv}}</span>{{$t("lang.vipprivileges")}}
					</div>
					<!-- <button class="btn-get" :disabled="!queryResult.promotion_list||queryResult.promotion_list.length<=0" :class="queryResult.promotion_list&&queryResult.promotion_list.length>0?'':'noget'" @click="openGetPrizeDialog">{{ $t("lang.click_collection") }}</button> -->
				</div>
				<table class="queryresult-table" cellspacing="0">
					<tr>
						<th v-for="item in queryTh" :key="item">{{item}}</th>
					</tr>
					<tr>
						<td>{{queryResult.promotion}}</td>
						<td>{{queryResult.lucky_bonus}}</td>
						<td>{{queryResult.birthday_bonus}}</td>
						<td>{{queryResult.festival_bonus}}</td>
						<td>{{queryResult.negative_bonus}}</td>
						<!-- <td>{{queryResult.month_save_promotion}}</td> -->
						<!-- <td><span v-if="queryResult.line==1" class="status correct"></span><span
								v-if="queryResult.line==0" class="status incorrect"></span></td>
						<td><span v-if="queryResult.service==1" class="status correct"></span><span
								v-if="queryResult.service==0" class="status incorrect"></span></td> -->
					</tr>
				</table>
				<div class="promotion dp-flex-row">
					<p v-if="queryResult.standard==4||queryResult.standard==5">{{ $t("lang.next_bet_need") }}:
						{{queryResult.next_need_bet}}</p>
					<p v-if="queryResult.standard==2||queryResult.standard==5">{{ $t("lang.next_need_save") }}:
						{{queryResult.next_need_save}}</p>
					<p>{{ $t("lang.level_after_promotion") }}: {{queryResult.next_vip_lv}}</p>
				</div>
			</div>
			<div class="dialog-cover" v-if="prizeDialog">
			</div>
			<div class="dialog-get dp-flex-column flex-ai-center" v-if="prizeDialog">
				<p class="p1">{{ $t("lang.get_title") }}</p>
				<div v-if="isGetPrize">
					<p class="p2 dp-flex-row flex-ai-center" v-for="(item,index) in queryResult.promotion_list"
						:key="index">{{item.typeName}}：<span class="money">{{item.money}}</span></p>
				</div>
				<div v-else>
					<p>{{ $t("lang.get_fail") }}</p>
				</div>
				<div @click="closePrizeDialog" class="btn-close">{{ $t("lang.concern") }}</div>
			</div>
		</div>

	</div>
</template>

<script>
	import {
		gameDatas,
		menberDatas
	} from "../../public/static/params";
	import config from '../common/config'
	export default {
		name: "HomePage",
		data() {
			return {
				isGetPrize: false,
				prizeDialog: false,
				timer: null,
				timer2: null,
				noAccount: false,
				footerData: "",
				queryVip: "",
				queryResult: {},
				siteName: "",
				customBeginDate: "",
				pcLogoUrl: "",
				mobileLogoUrl: "",
				vipAdvantages: [],
				luckData: [],
				monthGiftData: [],
				luckData1: [],
				luckData2: [],
				luckData3: [],
				monthGiftData1: [],
				monthGiftData2: [],
				templayteName: "",
				nowLang: "th",
				topImg: "",
				isQueryDia: false,
				vipTh: [],
				tab1Th: [],
				tab2Th: [],
				queryTh: [],
				tabIndex: 0,
				tabList: [],
				navIndex: 0,
				navList: [{
					navName: "",
					imgSrc: "",
					url: ""
				}],
				usdtAdvantages:[],
				selLanguage: {},
				langOptions: [
					// {
					// 	value: "zh",
					// 	label: "中文",
					// 	icon: "flag_china.png"
					// },
					{
						value: "th",
						label: "ไทย",
						icon: "flag_thai.png"
					},
					{
						value: "en",
						label: "English",
						icon: "flag_america.jpg"
					},
					{
						value: "usdt",
						label: "USDT",
						icon: "flag_america.jpg"
					}
					// {
					// 	value: "yn",
					// 	label: "Việt Nam",
					// 	icon: "flag_Vietnamese.png"
					// },
					// {
					// 	value: "id",
					// 	label: "Bhs indonesia",
					// 	icon: "flag_Indonesia.png"
					// }
				],
				gameData: gameDatas,
				menberData: menberDatas,
				promotionType: []
			};
		},
		created() {
			this.getConfigData();
			this.getTableData(1);
			this.getTableData(2);
			this.getTableData(3);
			this.getTableData(4);
			let queryLang = this.$route.query.lang;
			let lang = localStorage.getItem("language") || config.lang
			if (queryLang == "zh" || queryLang == "th" || queryLang == "en" || queryLang == "yn" || queryLang == "usdt") {
				lang = queryLang;
			}
			this.nowLang = lang;
			this.$i18n.locale = lang;
			this.vipTh = this.$t("lang.vipTh");
			this.tab1Th = this.$t("lang.tab1Th");
			this.tab2Th = this.$t("lang.tab2Th");
			this.usdtTh = this.$t("lang.usdtTh");
			this.queryTh = this.$t("lang.queryTh");
			this.tabList = this.$t("lang.tabData");
			this.promotionType = this.$t("lang.promotionType");
			this.navList[0].navName = this.$t("lang.vip_query");
			this.selLanguage = this.langOptions.find(item => {
				return item.value == this.$i18n.locale;
			});
			this.gameData = this.gameData[this.nowLang];
			this.menberData = this.menberData[this.nowLang];
			// if (lang == "th") {
			//   this.topImg = require("../assets/images/banner_th.png");
			// } else if (lang == "zh") {
			//   this.topImg = require("../assets/images/banner_zh.png");
			// }else if (lang == "yn") {
			//   this.topImg = require("../assets/images/banner_yn.png");
			// } else if (lang == "en") {
			//   this.topImg = require("../assets/images/banner_en.png");
			// }
		},
		mounted() {
			let dom1 = {
				comment1: "comment1",
				comment2: "comment2",
				reviewBox: "review_box"
			};
			let dom2 = {
				comment1: "ccomment1",
				comment2: "ccomment2",
				reviewBox: "review_box2"
			};
			this.roll(50, dom1, this.timer);
			this.roll(50, dom2, this.timer2);
		},
		methods: {
			closePrizeDialog() {
				this.getQueryResult(this.queryResult.user_name);
				this.prizeDialog = false;
			},
			openGetPrizeDialog() {
				this.$get("/api/vip/draw.html", {
					username: this.queryResult.user_name
				}).then(res => {
					if (res.data.code == 0) {
						this.isGetPrize = true;
					}
					this.prizeDialog = true;
					this.$refs.getVoice.play();
				});
			},
			roll(t, dom, timer) {
				var ul1 = document.getElementById(dom.comment1);
				var ul2 = document.getElementById(dom.comment2);
				var ulbox = document.getElementById(dom.reviewBox);
				ul2.innerHTML = ul1.innerHTML;

				ulbox.scrollTop = 0; // 开始无滚动时设为0
				clearInterval(timer);
				timer = setInterval(() => {
					if (ulbox.scrollTop >= ul1.scrollHeight) {
						ulbox.scrollTop = 0;
					} else {
						ulbox.scrollTop++;
					}
				}, t); // 设置定时器，参数t用在这为间隔时间（单位毫秒），参数t越小，滚动速度越快
				// 鼠标移入div时暂停滚动
				ulbox.onmouseenter = function() {
					clearInterval(timer);
				};
				// 鼠标移出div后继续滚动
				ulbox.onmouseleave = function() {
					timer = setInterval(() => {
						if (ulbox.scrollTop >= ul1.scrollHeight) {
							ulbox.scrollTop = 0;
						} else {
							ulbox.scrollTop++;
						}
					}, t);
				};
			},
			// 开始滚动函数
			// rollStart() {
			//   var ul1 = document.getElementById("comment1");
			//   var ul2 = document.getElementById("comment2");
			//   var ulbox = document.getElementById("review_box");
			//   if (ulbox.scrollTop >= ul1.scrollHeight) {
			//     ulbox.scrollTop = 0;
			//   } else {
			//     ulbox.scrollTop++;
			//   }
			// },
			changeLaguages(item) {
				this.$i18n.locale = item.value;
				this.selLanguage = item;
				localStorage.setItem("language", item.value);
				this.$router.replace("/");
				location.reload();
			},
			nFormatter(num, digits) {
				const si = [{
						value: 1,
						symbol: ""
					},
					{
						value: 1e3,
						symbol: "K"
					},
					{
						value: 1e6,
						symbol: "M"
					}
					// { value: 1e9, symbol: "G" },
					// { value: 1e12, symbol: "T" },
					// { value: 1e15, symbol: "P" },
					// { value: 1e18, symbol: "E" }
				];
				const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
				let i;
				for (i = si.length - 1; i > 0; i--) {
					if (num >= si[i].value) {
						break;
					}
				}
				return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
			},
			queryVipLevel() {
				if (!this.queryVip) {
					return;
				}
				this.getQueryResult();

				this.queryVip = "";
			},
			getConfigData() {
				this.$get("/api/sysconf.html").then(res => {
					if (res.data.code == 0) {
						let configData = res.data.data;
						document.title = configData.site_name;
						this.footerData = configData.site_copy;
						this.siteName = configData.site_name;
						this.pcLogoUrl = configData.pc_logo;
						this.mobileLogoUrl = configData.mobile_logo;
						this.customBeginDate = configData.custom_begin_date;
						this.templayteName = configData.custom_template_name;
						var regEx = new RegExp("\\-", "gi");

						

						if (this.nowLang != "zh") {
							this.customBeginDate = this.customBeginDate.replace(regEx, "/");
						}
						if (configData.home_url) {
							let msg1 = {
								navName: this.$t("lang.home_page"),
								url: configData.home_url,
								imgSrc: ""
							};
							this.navList.push(msg1);
						}
						if (configData.activity_url) {
							let msg2 = {
								navName: this.$t("lang.preferential_activities"),
								url: configData.activity_url,
								imgSrc: ""
							};
							this.navList.push(msg2);
						}
						if (configData.cs_url) {
							let msg3 = {
								navName: this.$t("lang.online_service"),
								url: configData.cs_url,
								imgSrc: ""
							};
							this.navList.push(msg3);
						}
						if (configData.line_cs_url && this.nowLang == 'th') {
							let msg4 = {
								navName: "Line",
								url: configData.line_cs_url,
								imgSrc: require("../assets/images/line.png")
							};
							this.navList.push(msg4);
						}
						if (configData.whatsapp_cs_url.length && this.nowLang == 'id') {
							let arr = configData.whatsapp_cs_url
							let index = Math.floor((Math.random()*arr.length)); 
							let msg5 = {
								navName: "Whatsapp",
								url: arr[index],
								imgSrc: require("../assets/images/whatsapp.png")
							};
							this.navList.push(msg5);
						}
						if (configData.zalo_cs_url.length && this.nowLang == 'yn') {
							let arr = configData.zalo_cs_url
							let index = Math.floor((Math.random()*arr.length)); 
							let msg6 = {
								navName: "Zalo",
								url: arr[index],
								imgSrc: require("../assets/images/zalo.png")
							};
							this.navList.push(msg6);
						}
						console.log(this.navList);
					}
				});
			},
			getTableData(typeData) {
				// if (typeData == 4) {
				// 	return;
				// }
				let params = {}
				if(typeData === 4) {
					params = {
						currency:'USDT'
					}
				}else {
					params = {
						type: typeData,
					}
				}
				this.$get("/api/vip/level.html", params).then(res => {
					if (res.data.code == 0) {
						if (typeData == 1) {
							this.vipAdvantages = res.data.data;
							this.vipAdvantages.forEach((item, index) => {
								// item.a_drop = this.bigNumberTransform(item.a_drop);
								item.a_drop_trans = this.nFormatter(item.a_drop, 2);
							});
						}
						if (typeData == 2) {
							let luckData = res.data.data;
							luckData.forEach((item, index) => {
								// item.m_drop = this.bigNumberTransform(item.m_drop);
								item.m_drop_trans = this.nFormatter(item.m_drop, 2);
							});
							this.luckData1 = luckData.filter((item, index) => index <= 9);
							this.luckData2 = luckData.filter((item, index) => index > 9 && index <= 19);
							this.luckData3 = luckData.filter((item, index) => index > 19);
						}
						if (typeData == 3) {
							let monthGiftData = res.data.data;
							this.monthGiftData1 = monthGiftData.filter((item, index) => index <= 14);
							this.monthGiftData2 = monthGiftData.filter((item, index) => index > 14);
						}
						if (typeData == 4) {
							this.usdtAdvantages = res.data.data;
						}
					}
				});
			},
			getQueryResult(user) {
				this.noAccount = false;
				let params = {}
				if(this.nowLang == 'usdt') {
					params = {
						username: user ? user : this.queryVip,
						currency : "USDT"
					}
				}else {
					params = {
						username: user ? user : this.queryVip,
					}
				}
				this.$get("/api/vip/query.html", params).then(res => {
					if (res.data.code == 0) {
						this.queryResult = res.data.data;
						this.queryResult.promotion_list = [{
							name: "月月负利",
							money: 885,
							type: 1
						}];
						if (this.queryResult.promotion_list && this.queryResult.promotion_list.length > 0) {
							this.queryResult.promotion_list.forEach(item => {
								item.typeName = this.promotionType[item.type];
							});
						}
					} else if (res.data.code == 10001) {
						this.noAccount = true;
					}
					this.isQueryDia = true;
				});
			},
			changeNav(index) {
				this.navIndex = index;
			},
			changeTab(index) {
				this.tabIndex = index;
			}
		},
		beforeDestroy() {
			clearInterval(this.timer);
			clearInterval(this.timer2);
		}
	};
</script>
<style scoped lang="scss">
	.langBox {
		position: absolute;
		right: 0;
		top: 20px;
		margin-left: 100px;

		// width: 100px;
		span {
			color: #fff;
			font-size: 15px;
		}

		img {
			width: 20px;
			height: 14px;
			margin-right: 5px;
		}

		.selLang {
			cursor: pointer;
		}
	}

	td {
		text-align: center;

		.correct:before {
			content: "\2714";
			color: #008100;
		}

		.incorrect:before {
			content: "\2716";
			color: #b20610;
		}
	}

	.cover {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.8);
		z-index: 100;
	}

	.dialog-query {
		position: fixed;
		width: 900px;
		background-color: #fff;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 400;

		.dia-top {
			width: 100%;
			height: 50px;
			background-image: linear-gradient(#1474cd, #10509a, #0b2b68);
			color: #fff;
			font-size: 24px;
			letter-spacing: 2px;
			position: relative;

			.t-queryresult {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			.incorrect:before {
				content: "\2716";
				color: #fff;
				position: absolute;
				top: 50%;
				right: 30px;
				transform: translateY(-50%);
				cursor: pointer;
			}
		}

		.no-account {
			padding: 30px 0;
			font-size: 26px;
		}

		.info {
			font-size: 18px;
			color: #000;
			font-weight: 700;

			p {
				margin-left: 20px;
				margin-top: 26px;
				text-align: center;
			}

			p:first-child {
				margin-left: 0;
			}

			span {
				color: #ff0000;
			}
		}

		.vip-levlel {
			position: relative;
			height: 48px;
			width: 100%;
			margin-top: 24px;
			margin-bottom: 10px;

			.img-vipprivileges {
				font-size: 40px;
				font-weight: 900;
				position: absolute;
				left: 50%;
				transform: translatex(-50%);
			}

			.btn-get {
				padding: 8px 14px;
				background-color: #fff;
				border: 1px solid red;
				color: red;
				font-weight: 700;
				border-radius: 4px;
				vertical-align: top;
				position: absolute;
				right: 40px;
				top: 50%;
				transform: translateY(-50%);
				cursor: pointer;
				outline: 0;
			}

			.btn-get.noget {
				background-color: #fff;
				border: 1px solid #ddd;
				color: #ddd;
				cursor: not-allowed;
			}
		}

		.img-vipprivileges {
			font-size: 36px;
			line-height: 1.4;
			display: inline-block;
			font-weight: bolder;
			background-image: -webkit-linear-gradient(#f49e23, #eaea34, #f49e23);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			border: #000000;
		}

		.queryresult-table {
			width: 90%;
			margin-top: 10px;
			border-left: 1px solid #bfbfbf;
			border-top: 1px solid #bfbfbf;

			tr {
				color: #333;
				font-size: 15px;
				height: 44px;

				th {
					border-right: 1px solid #bfbfbf;
					border-bottom: 1px solid #bfbfbf;
					width: 12.5%;
				}

				td {
					width: 12.5%;
					color: #ff0000;
					text-align: center;
					border-right: 1px solid #bfbfbf;
					border-bottom: 1px solid #bfbfbf;
				}
			}
		}

		.promotion {
			color: #0005bf;
			font-size: 20px;
			margin-top: 20px;
			margin-bottom: 30px;
			justify-content: space-around;
			padding: 0px 16px;

			p {
				text-align: center;
				margin-left: 30px;
			}

			p:first-child {
				margin-left: 0;
			}
		}

		.dialog-cover {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 700;
			background-color: rgba(0, 0, 0, 0.5);
		}

		.dialog-get {
			position: absolute;
			background: url(../assets/images/img_red.png) no-repeat;
			background-size: 100% 100%;
			width: 380px;
			min-height: 488px;
			height: auto;
			// width: 400px;
			// background-color: #fff;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -60%);
			z-index: 800;
			border-radius: 3px;
			color: #fff;

			.p1 {
				font-weight: 700;
				// margin-top: 30px;
				margin-top: 60%;
				margin-bottom: 20px;
			}

			.p2 {
				margin-top: 8px;

				.money {
					font-size: 40px;
					font-weight: 700;
					color: #ffcf5e;
				}
			}

			.btn-close {
				width: 300px;
				height: 70px;
				text-align: center;
				// line-height: 58px;
				margin-bottom: 30px;
				padding-top: 13px;
				box-sizing: border-box;
				// background-color: #1474cd;
				color: rgb(59, 59, 59);
				border-radius: 2px;
				font-size: 20px;
				font-weight: 700;
				margin-top: 60px;
				cursor: pointer;
				background: url(../assets/images/img_concern.png) no-repeat center center;
				background-size: 100% 100%;
			}
		}
	}

	.nav {
		height: 100px;
		background-image: linear-gradient(#1474cd, #10509a, #0b2b68);

		.cen-nav {
			width: 1200px;
			background-image: linear-gradient(#1474cd, #10509a, #0b2b68);
			height: 100%;
			margin: 0 auto;
			position: relative;

			.img-logo {
				height: 70%;
				width: auto;
				position: relative;
				top: 50%;
				transform: translateY(-50%);
				margin-left: 20px;
			}

			.nav-content {
				// position: absolute;
				// right: 60px;
				height: 100%;
				margin-left: 30px;

				// bottom: 20px;
				ul {
					position: relative;
					top: 32px;
					// top: 50%;
					// transform: translateY(-50%);
					list-style-type: none;
					font-size: 22px;
					color: #fff;

					li {
						margin-left: 5px;
						position: relative;

						padding: 20px 10px;

						// div {
						//   cursor: pointer;
						//   padding: 20px 10px;
						//   position: relative;
						// }
						.navitem {
							cursor: pointer;
							padding: 20px 10px;
							position: relative;
						}

						img {
							width: 20px;
							height: 20px;
							position: absolute;
							top: 15px;
							right: -10px;
						}

						.navline {
							width: 100%;
							position: absolute;
							bottom: -20px;
							bottom: 0;
							height: 4px;
							left: 0;
							background-color: #f39800;
						}
					}
				}
			}
		}
	}

	.top-img {
		position: relative;

		img:first-child {
			width: 100%;
			min-width: 1200px;
		}

		.img-prize,
		.img-prize2 {
			position: absolute;
			width: 27%;
			min-width: 300px;
			right: 50px;
			z-index: 100;
			height: 48%;

			.prize-title {
				position: absolute;
				width: 80%;
				top: 20%;
				left: 10%;
				// padding: 0 9px;

				span {
					width: 30%;
					text-align: left;
					font-weight: 700;
					color: #1056a4;
					font-size: 16px;
				}

				span:first-child {
					width: 40%;
					padding-left: 21px;
					box-sizing: border-box;
				}

				span:last-child {
					width: 30%;
					padding-left: 19px;
					box-sizing: border-box;
				}
			}

			.review_box {
				position: absolute;
				// width: 70%;
				width: 80%;
				height: 58%;
				bottom: 10.6%;
				left: 10%;
				overflow: hidden;

				// padding: 0 9px;
				// background-color: red;
				ul {
					list-style: none;

					li {
						font-size: 12px;
						margin-top: 10px;
						color: #efbc50;

						span {
							width: 30%;
							text-align: left;
						}

						span:first-child {
							width: 40%;
							padding-left: 21px;
							box-sizing: border-box;
						}

						span:last-child {
							width: 30%;
							padding-left: 19px;
							box-sizing: border-box;
						}
					}
				}
			}
		}

		.img-prize {
			top: 1%;
			background: url(../assets/images/game_pc_th.png) no-repeat;
			background-size: 100% 100%;
		}

		.img-prize_yn {
			background: url(../assets/images/game_pc_yn.png) no-repeat;
			background-size: 100% 100%;
		}

		.img-prize_en {
			background: url(../assets/images/game_pc_en.png) no-repeat;
			background-size: 100% 100%;
		}

		.img-prize_th {
			background: url(../assets/images/game_pc_th.png) no-repeat;
			background-size: 100% 100%;
		}
		
		.img-prize_usdt {
			background: url(../assets/images/game_pc_th.png) no-repeat;
			background-size: 100% 100%;
		}

		.img-prize_zh {
			background: url(../assets/images/game_pc_zh.png) no-repeat;
			background-size: 100% 100%;
		}

		.img-prize_id {
			background: url(../assets/images/game_pc_id.png) no-repeat;
			background-size: 100% 100%;
		}

		.img-prize2 {
			top: 50%;
			background: url(../assets/images/bonus_pc_th.png) no-repeat;
			background-size: 100% 100%;
		}

		.img-prize2_yn {
			background: url(../assets/images/bonus_pc_yn.png) no-repeat;
			background-size: 100% 100%;
		}

		.img-prize2_en {
			background: url(../assets/images/bonus_pc_en.png) no-repeat;
			background-size: 100% 100%;
		}
		.img-prize2_th {
			background: url(../assets/images/bonus_pc_th.png) no-repeat;
			background-size: 100% 100%;
		}
		
		.img-prize2_usdt {
			background: url(../assets/images/bonus_pc_th.png) no-repeat;
			background-size: 100% 100%;
		}

		.img-prize2_zh {
			background: url(../assets/images/bonus_pc_zh.png) no-repeat;
			background-size: 100% 100%;
		}

		.img-prize2_id {
			background: url(../assets/images/bonus_pc_id.png) no-repeat;
			background-size: 100% 100%;
		}
	}

	.content-wrap {
		width: 100%;
		background: url(../assets/images/bg_white.png);
		padding-bottom: 75px;
	}

	.content {
		width: 1200px;

		background-size: 100% 100%;
		margin: 0 auto;

		.search-box {
			width: 932px;
			height: 84px;
			background-image: linear-gradient(#1474cd, #10509a, #0b2b68);
			border-radius: 42px;
			margin-top: 44px;
			padding: 0 30px;
			box-sizing: border-box;

			.t-vipquery {
				font-size: 28px;
				color: #fff;
			}

			input {
				width: 460px;
				height: 62px;
				border-radius: 31px;
				// margin-left: 30px;
				padding: 0 20px;
				box-sizing: border-box;
				border: 0;
				font-size: 26px;
				outline: none;
			}

			input::-webkit-input-placeholder {
				/* WebKit browsers */
				color: rgb(80, 78, 78);
				font-size: 24px;
			}

			input:-moz-placeholder {
				/* Mozilla Firefox 4 to 18 */
				color: rgb(80, 78, 78);
				font-size: 26px;
			}

			input::-moz-placeholder {
				/* Mozilla Firefox 19+ */
				color: rgb(80, 78, 78);
				font-size: 26px;
			}

			input:-ms-input-placeholder {
				/* Internet Explorer 10+ */
				color: rgb(80, 78, 78);
				font-size: 26px;
			}

			.btn-query {
				border: 0;
				width: 146px;
				height: 63px;
				border-radius: 31px;
				font-weight: 900;
				background-image: linear-gradient(0deg, #daa23e 11%, #8e541c 25%, #daa23e 38%, #ffcf5e 45%, #fff9e8 100%), linear-gradient(#122342, #122342);
				font-size: 30px;
				color: #122342;
				text-align: center;
				line-height: 63px;
				// margin-left: 30px;
				cursor: pointer;

				img {
					width: 100%;
					height: 100%;
				}
			}
		}

		.tab-box {
			width: 1080px;
			height: 80px;
			margin-top: 64px;

			.tab-item {
				font-size: 30px;
				color: #fff;
				width: 50%;
				background: url(../assets/images/btn_tab.png) no-repeat;
				background-size: 100% 100%;
				text-align: center;
				line-height: 1.2;
				cursor: pointer;
				position: relative;

				p {
					width: 100%;
					padding: 0 20px;
					box-sizing: border-box;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}

			.tabactive.tab-item {
				background: url(../assets/images/btn_tab_active.png) no-repeat;
				background-size: 100% 100%;
				color: #122342;
				font-weight: 900;
				font-size: 36px;
				line-height: 1;
			}

			.tab-item:first-child {
				background: url(../assets/images/new_btn_tabfirst.png) no-repeat;
				background-size: 100% 100%;
			}

			.tabactive.tab-item:first-child {
				background: url(../assets/images/new_btn_tabfirst_active.png) no-repeat;
				background-size: 100% 100%;
				color: #122342;
				font-weight: 900;
				// font-size: 32px;
			}

			.tab-item:last-child {
				background: url(../assets/images/new_btn_tablast.png) no-repeat;
				background-size: 100% 100%;
			}

			.tabactive.tab-item:last-child {
				background: url(../assets/images/new_btn_tablast_active.png) no-repeat;
				background-size: 100% 100%;
				color: #122342;
				font-weight: 900;
				// font-size: 32px;
			}
		}

		.tab-content {
			width: 1080px;
			padding: 15px 40px;
			box-sizing: border-box;
			background: url(../assets/images/bg_mid.png);
			background-repeat: repeat-y;

			.content-1 {
				font-size: 22px;
				font-weight: bold;
				color: #122342;
				line-height: 36px;
			}

			.content-2 {
				color: #ff0000;
				font-size: 18px;
				margin-top: 25px;
			}

			.p-content {
				margin-top: 20px;
				line-height: 1.5;
			}

			.content-3 {
				width: 100%;
				margin-top: 25px;
				border-left: 1px solid #d5b99e;
				border-top: 1px solid #d5b99e;

				tr {
					color: #f4f7ff;
					height: 44px;

					th {
						background-color: #d5b99e;
						width: 10%;
					}

					td {
						width: 10%;
						color: #000;
						text-align: center;
						border-right: 1px solid #d5b99e;
						border-bottom: 1px solid #d5b99e;
					}
				}
			}
		}

		.tab1 {
			.tabItem {
				margin: 20px 5px;
				// margin-top: ;
				flex: 1;
			}

			.tab1-p1 {
				color: #ff0000;
				font-weight: 700;
				margin-bottom: 10px;
				font-size: 22px;
			}

			.tab1-p2 {
				font-size: 18px;
			}

			.tab1-p3 {
				margin-top: 25px;
			}

			.tab1-p4 {
				color: #ff0000;
				margin-top: 5px;
			}

			.tab1-table1,
			.tab1-table2,
			.tab1-table3 {
				width: 100%;
				border-left: 1px solid #d5b99e;
				border-top: 1px solid #d5b99e;

				tr {
					color: #f4f7ff;
					height: 44px;

					th {
						background-color: #d5b99e;
						width: 10%;
					}

					td {
						width: 10%;
						color: #000;
						text-align: center;
						border-right: 1px solid #d5b99e;
						border-bottom: 1px solid #d5b99e;
					}
				}
			}
		}

		.tab2 {
			.tab2-p1 {
				color: #ff0000;
				font-weight: 700;
				font-size: 22px;
				margin-bottom: 10px;
			}

			.tab2-table1,
			.tab2-table2 {
				width: 49%;
				margin-top: 25px;
				border-left: 1px solid #d5b99e;
				border-top: 1px solid #d5b99e;

				tr {
					color: #f4f7ff;
					height: 44px;

					th {
						background-color: #d5b99e;
						width: 10%;
					}

					td {
						width: 10%;
						color: #000;
						text-align: center;
						border-right: 1px solid #d5b99e;
						border-bottom: 1px solid #d5b99e;
					}
				}
			}
		}

		.tab3 {
			.tab3-p1 {
				color: #ff0000;
				font-size: 18px;
				font-weight: 700;
			}

			.tab3-p2 {
				margin-bottom: 30px;
			}

			.tab3-center {
				text-align: center;
			}

			.tab3-p4 {
				margin-bottom: 30px;
				color: #ff0d00;
			}
		}

		.tab-content-bgtop {
			margin-top: 30px;
			width: 1080px;
			height: 45px;
			background: url(../assets/images/bg_top.png) no-repeat;
		}

		.tab-content-bgbot {
			width: 1080px;
			height: 45px;
			background: url(../assets/images/bg_bot.png) no-repeat;
		}
	}

	.footer {
		width: 100%;
		height: 76px;
		background-color: #0b2b68;
		color: #d4d4d4;

		p {
			width: 1200px;
			height: 100%;
			margin: 0 auto;
		}
	}
</style>
